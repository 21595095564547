import { defineStore } from "pinia";
import { getOfficeIdByNativeId } from "@/common/api.service";
import { getCurrentDateTimeAsString, DateTimeInput } from "@/common/time";

export const useRequestStore = defineStore("request", {
  state: () => ({
    actionStatus: "ST_STARTRQST",
    aspect: "",
    bottomElevation: 0,
    currentUpdate: 0,
    coordinates: [0, 0],
    id: 0,
    initialCoordinates: [-100, 41],
    deliverAt: new DateTimeInput(),
    draftForecast: "",
    drainage: "",
    fireZoneOfficeId: "",
    fireZoneSiteId: "LBF",
    fuelType: "",
    futureForecastSchedules: [],
    incident: {},
    incidentPFReason: "",
    isDaylightSavings: true,
    isDispatched: false,
    isEditing: false,
    isHysplit: "No",
    observations: [],
    office: "",
    projectName: "",
    quad: "",
    question: "",
    remarks: "",
    requestElements: [],
    requestFormats: [],
    requesterAgency: "",
    requesterEmail: "",
    additionalEmails:[],
    requesterFax: "",
    requesterMeta: navigator.userAgent,
    requesterName: "",
    requesterOfficial: "",
    requesterPhone: "",
    requesterPhoneExt: "",
    sheltering: "",
    siteId: "",
    size: 0,
    spotForecasts: [],
    startedAt: new DateTimeInput(),
    state: "",
    submittedAt: getCurrentDateTimeAsString(),
    Timezone: {},
    TimezonesSupported: [],
    topElevation: 0,
    viewableIncidents: [],
    zoom: 4.5,
    '@id': 0,
    '@type': "",
  }),
  persist: {
    storage: sessionStorage,
    paths: [
      "aspect",
      "bottomElevation",
      "drainage",
      "fireZoneSiteId",
      "fuelType",
      "futureForecastSchedules",
      "id",
      "incident",
      "incidentPFReason",
      "isEditing",
      "isHysplit",
      "latitude",
      "longitude",
      "observations",
      "office",
      "projectName",
      "quad",
      "question",
      "remarks",
      "requestElements",
      "requestFormats",
      "requesterAgency",
      "requesterOfficial",
      "requesterEmail",
      "requesterPhone",
      "requesterPhoneExt",
      "requesterFax",
      "requesterName",
      "siteId",
      "sheltering",
      "size",
      "state",
      "Timezone",
      "TimezonesSupported",
      "topElevation",
      "@id",
      "@type",
    ],
  },
  getters: {
    longitude() {
      return Number(this.coordinates[0].toFixed(4));
    },
    latitude() {
      return Number(this.coordinates[1].toFixed(4));
    },
    elevation() {
      return this.topElevation;
    },
    newRequestData() {
      let newRequest = this.createBaseRequest();
      newRequest.Timezone = this.Timezone.id;
      newRequest.incident = this.incident.id;

      // ID can not be sent for a new request.
      delete newRequest.id;

      return newRequest;
    },
    editRequestData() {
      let editRequest = this.createBaseRequest();
      editRequest.incident = this.incident["@id"];
      editRequest.Timezone = this.Timezone["@id"];
      editRequest.office = this.office["@id"];
      return editRequest
    }
  },
  actions: {
    setOfficeId(id) {
      this.office = id;
    },
    setSiteId(id) {
      this.siteId= id;
    },
    setIsEditing(editing) {
      this.isEditing = editing;
    },
    setIncidentId(id) {
      console.debug(`setting id ${id}`);
      this.incidentId = `/incidents/${id}`;
    },
    setCoordinates(longitude, latitude) {
      this.coordinates[0] = longitude;
      this.coordinates[1] = latitude;
    },
    setStartedAt(startDate, startTime) {
      this.startedAt = new DateTimeInput(startDate, startTime);
    },
    setDeliverAt(deliverDate, deliverTime) {
      this.deliverAt = new DateTimeInput(deliverDate, deliverTime);
    },
    setTimezone(timezone) {
      this.Timezone = timezone;
    },
    setTimezonesSupported(timezonesSupported) {
      timezonesSupported.forEach((timezone) => {
        this.TimezonesSupported.push(timezone);
      });
    },
    setEmails(emails){
      this.additionalEmails = emails.split(",");
      this.requesterEmail = "";
      console.log(this.additionalEmails);
    },
    async getOfficeId(nativeSiteId) {
      return getOfficeIdByNativeId(nativeSiteId);
    },
    createBaseRequest() {
      let newRequest = Object.assign({}, this.$state);

      // Concat emails into a string
      for (let i = newRequest.additionalEmails.length - 1; i >= 0; i--) {
        if (newRequest.additionalEmails[i] === undefined || 
            newRequest.additionalEmails[i] === null || 
            newRequest.additionalEmails[i] === ""
          ) {
          newRequest.additionalEmails.splice(i, 1);
        }
      }
      newRequest.requesterEmail = newRequest.additionalEmails.join(",");

      //Set longitude & latitude fields for request JSON payload
      newRequest.longitude = this.longitude;
      newRequest.latitude = this.latitude;

      newRequest.futureForecastSchedules.forEach(futureDateTime => {
        const future = new Date(futureDateTime.dateSchedule);
        const startingDateTime = new Date(this.startedAt.date + 'T' + this.startedAt.time);
        if (future < startingDateTime) {
          /*
           * If any FFS is before the forecast starting time, set it to null so
           * it will be removed with the filter below.
           */
          futureDateTime.dateSchedule = null;
        }

      // Remove invalid future forecasts
      newRequest.futureForecastSchedules =
        newRequest.futureForecastSchedules.filter(x => x.dateSchedule !== null);
      });

      newRequest.deliverAt = this.deliverAt.value();
      newRequest.startedAt = this.startedAt.value();

      // Remove data not needed for the request.
      delete newRequest.viewableIncidents;
      delete newRequest.initialCoordinates;
      delete newRequest.coordinates;
      delete newRequest.draftForecast;
      delete newRequest.isEditing;
      delete newRequest.zoom;
      delete newRequest.fireZoneOfficeId;
      delete newRequest.fireZoneSiteId;
      delete newRequest.TimezonesSupported;

      return newRequest;
    },

    isValid() {
      /*
       * I could only find one article somewhat relevant to using a pinia store along with
       * vuelidate. I don't know how to get those 2 to work together, so for now I put in
       * this method to ensure all the required data set before enabling the submit button.
       * This will at least for now resolve this issue as the deadline closes in.
       */
      if (this.projectName.length < 3) {
        return false;
      }
      if (this.requesterAgency.length < 2) {
        return false;
      }
      if (this.requesterOfficial.length < 2) {
        return false;
      }
      if (this.additionalEmails.length !== 0) {
        let validEmail ="";
        for(let i =0; i < this.additionalEmails.length; i++){
          validEmail = String(this.additionalEmails[i])
            .toLowerCase()
            .match(
              /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/i
            );
          if (validEmail === false || validEmail === null) {
            return validEmail;
          }
        }
      } 
      else{
        return false;
      }
      if (this.requesterPhone !== "") {
        const validPhone = String(this.requesterPhone)
          .toLowerCase()
          .match(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/i);
        if (validPhone === false) {
          return validPhone;
        }
      }
      if (
        // Validete category and size, aspect, fuelType, sheltering
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        Number(this.size).valueOf() < 0
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.aspect.length < 1
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.fuelType.length < 2
      ) {
        return false;
      }
      if (
        this.incident.category !== undefined &&
        this.incident.category.toLowerCase().includes("fire") === true &&
        this.sheltering === ""
      ) {
        return false;
      }
      return true;
    },
  },
});
